import React, { useState, useEffect } from 'react';
import Template from '../components/Template';
import GroupPhoto from "../images/projects_photo.jpg";
import LoadingSpinner from "../components/LoadingSpinner";
import ProjectCard from '../components/ProjectCard';

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;
const AIRTABLE_API_URL = 'https://api.airtable.com/v0/appa0JVKXzBq5kEuA/Projects';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${AIRTABLE_API_URL}?sort%5B0%5D%5Bfield%5D=Year&sort%5B0%5D%5Bdirection%5D=desc`, {
          headers: {
            Authorization: `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setProjects(data.records || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data from Airtable:', error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const renderProjects = () => {
    return projects.map((project) => {
      const fields = project.fields;
      return (
        <ProjectCard
          key={project.id}
          imageUrl={fields.Picture}
          title={fields['Project Title']}
          summary={fields['Project Summary']}
          year={fields.Year}
          wikiLink={fields['Link To Wiki']}
        />
      );
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Template>
      <div className="container">
        <div className="content-container">
          <div className="home-photo" style={{backgroundImage: `url(${GroupPhoto})`}}>
            <div className="overlay"></div>
            <div className="text-container-center">
              <h1>IGEM PROJECTS</h1>
              <p>Each year, Stanford iGEM embarks on innovative projects spanning multidisciplinary fields. Explore Stanford iGEM's research journey from 2009 to the present!</p>
            </div>
          </div>
          <div className="section">
            {renderProjects()}
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Projects;
