// src/components/NewsCard.js
import React from 'react';

const NewsCard = ({ title, date, photo }) => {
  return (
    <div className="news-card">
      <img src={photo} alt={title} className="news-card-image" />
      <div className="news-card-content">
        <h3 className="news-card-title">{title}</h3>
        <p className="news-card-date">{date}</p>
      </div>
    </div>
  );
};

export default NewsCard;
