import React, { useState, useEffect, useRef } from 'react';
import Template from '../components/Template';
import NewsCard from '../components/NewsCard';
import LoadingSpinner from '../components/LoadingSpinner'; // Import LoadingSpinner component
import './photos.css'; // Ensure your existing styles are imported correctly
import moment from 'moment'; // Import moment.js for date handling

import GroupPhoto from "../images/drew.jpg";

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;

const Photos = () => {
  const [images, setImages] = useState([]);
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state
  const carouselRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const photoResponse = await fetch(
          'https://api.airtable.com/v0/appa0JVKXzBq5kEuA/Photos',
          {
            headers: {
              Authorization: `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const photoData = await photoResponse.json();
        const imageUrls = photoData.records.map(record => ({
          original: record.fields.Photo[0].url,
          thumbnail: record.fields.Photo[0].thumbnails.large.url,
          caption: record.fields.Caption,
        }));
        setImages(imageUrls);

        const newsResponse = await fetch(
          'https://api.airtable.com/v0/appa0JVKXzBq5kEuA/News',
          {
            headers: {
              Authorization: `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const newsData = await newsResponse.json();
        const newsRecords = newsData.records.map(record => ({
          title: record.fields.Title,
          date: moment(record.fields.Date).format('YYYY-MM-DD'), // Format date as needed
          photo: record.fields.Photo[0].url,
        })).sort((a, b) => moment(b.date).toDate() - moment(a.date).toDate());
        setNews(newsRecords);

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, []);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      const scrollAmount = window.innerWidth <= 768 ? 230 : 330; // Adjust scroll amount based on screen width
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      const scrollAmount = window.innerWidth <= 768 ? 230 : 330; // Adjust scroll amount based on screen width
      const carousel = carouselRef.current;
      const scrollWidth = carousel.scrollWidth;
      const visibleWidth = carousel.offsetWidth;
      const currentPosition = carousel.scrollLeft;
      const remainingScroll = scrollWidth - (currentPosition + visibleWidth);

      if (remainingScroll <= scrollAmount - 100) {
        carousel.scrollTo({ left: 0, behavior: 'smooth' });
      } else {
        carousel.scrollBy({ left: scrollAmount, behavior: 'smooth' });
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />; // Display loading spinner while fetching data
  }

  return (
    <Template>
      <div className="container">
        <div className="content-container">
          <div className="home-photo" style={{ backgroundImage: `url(${GroupPhoto})` }}>
            <div className="overlay"></div>
            <div className="text-container-center">
              <h1>NEWS & PHOTOS</h1>
              <p>View our gallery showcasing recent news articles, captivating
                photos capturing memorable moments, notable academic
                achievements, and more exciting updates!</p>
            </div>
          </div>
          <div className="section-no-space">
            <h2>NEWS</h2>
            <div className="news-box">
              <button className="carousel-button prev" onClick={handlePrevClick}>‹</button>
              <div className="carousel-container">
                <div className="carousel" ref={carouselRef}>
                  {news.map((newsItem, index) => (
                    <NewsCard
                      key={index}
                      title={newsItem.title}
                      date={moment(newsItem.date).format('MMMM D, YYYY')} // Format date for display
                      photo={newsItem.photo}
                    />
                  ))}
                </div>
              </div>
              <button className="carousel-button next" onClick={handleNextClick}>›</button>
            </div>
          </div>
          <div className="section">
            <h2>PHOTOS</h2>
            <div id="images-wrapper" className="photos-grid">
              {images.map((image, index) => (
                <div key={index} className="image-container">
                  <img src={image.thumbnail} alt={image.caption} className="carousel-image" />
                  <div className="image-caption">{image.caption}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Photos;
