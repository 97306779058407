import React, { useState, useEffect, useRef } from 'react';

const CountUp = ({ end, duration }) => {
  const [count, setCount] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const countRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
  
    const currentRef = countRef.current; // Store countRef.current in a variable
  
    if (currentRef) {
      observer.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      let start = 0;
      const increment = end / (duration / 50);

      const timer = setInterval(() => {
        start += increment;
        if (start >= end) {
          clearInterval(timer);
          start = end;
        }
        setCount(Math.ceil(start));
      }, 50);

      return () => clearInterval(timer);
    }
  }, [isInView, end, duration]);

  return <h2 ref={countRef}>{count}</h2>;
};

export default CountUp;
