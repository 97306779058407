import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer>
            <div className="footer-content">
                <div className="header-row">
                    <h1 className="header-text">STANFORD IGEM</h1>
                </div>
                <div className="column">
                    <p className="column-heading">Contact Us</p>
                    <div className="contact-info">
                        <p>Stanford iGEM Team: <br></br><a href="mailto:stanfordigemteam@gmail.com">stanfordigemteam@gmail.com</a></p>
                    </div>
                </div>
                <div className="column">
                    <p className="column-heading">Navigation</p>
                    <ul className="footer-links">
                        <li><a href="/">Home</a></li>
                        <li><a href="/projects">Projects</a></li>
                        <li><a href="/members">Members</a></li>
                        <li><a href="/education">Education</a></li>
                        <li><a href="/news-and-photos">News & Photos</a></li>
                        <li><a href="/join-us">Join iGEM</a></li>
                    </ul>
                </div>
                <div className="column">
                    <p className="column-heading">Social Media</p>
                    <div className="social-icons">
                        <a href="https://twitter.com/igemStanford23" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                        <a href="https://www.instagram.com/stanford_igem/?hl=en" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
