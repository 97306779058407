import React from 'react';
import Template from '../components/Template';
import GroupPhoto from "../images/group_photo_3.jpg";

const JoinUs = () => {
  return (
    <Template>
      <div className="container">
        <div className="content-container">
          <div className="home-photo" style={{backgroundImage: `url(${GroupPhoto})`}}>
            <div className="overlay"></div>
            <div className="text-container-center">
              <h1>JOIN IGEM</h1>
              <p>Stanford iGEM is a Voluntary Student Organization (VSO) dedicated to synthetic biology research and all things related to iGEM!</p>
            </div>
          </div>
          <div id="what-is-igem" className="section">
            <h2>HOW CAN I JOIN?</h2>
            <p>As a Voluntary Student Organization (VSO) at Stanford, Stanford iGEM is open to all students across different disciplines and academic levels. Whether you're an undergraduate or graduate student, we welcome you to join our innovative and collaborative community. Participating in Stanford iGEM offers a unique opportunity to engage in cutting-edge synthetic biology research, develop technical and leadership skills, and contribute to impactful projects.</p>
            <p>To become a part of our vibrant team, stay updated with our activities, and receive information about upcoming meetings, events, and opportunities, we encourage you to subscribe to our mailing list using your Stanford email.</p>

            <div className="buttons">
              <button onClick={() => window.open("https://mailman.stanford.edu/mailman/listinfo/stanfordigem-mailing-list", "_blank")}>
                Join the Mailing List
              </button>
            </div>
        </div>
        <div className="section">
                <h2>UPCOMING EVENTS</h2>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <iframe
                    src="https://calendar.google.com/calendar/embed?src=fb1e42cafa95129dc49fc54204d31c8448d7db54707a3fb78e3237892b470fc3%40group.calendar.google.com&ctz=America%2FLos_Angeles"
                    title="Google Calendar"
                    width="800"
                    height="600"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
      </div>
    </Template>
  );
};

export default JoinUs;
