import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './App.css';
import Home from './pages/Home';
import Members from './pages/Members';  
import Projects from './pages/Projects'; 
import Education from './pages/Education'; 
import Photos from './pages/Photos';
import JoinUs from './pages/JoinUs';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; 
};

function App() {
  return (
    <Router>
      <ScrollToTop /> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/members" element={<Members />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/education" element={<Education />} />
        <Route path="/news-and-photos" element={<Photos />} />
        <Route path="/join-us" element={<JoinUs />} />
      </Routes>
    </Router>
  );
}

export default App;
