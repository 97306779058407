import React, { useState, useEffect } from "react";
import Template from '../components/Template';
import TeamCard from "../components/TeamCard";
import GroupPhoto from "../images/group_photo_2.png"; 
import './team.css';

import LoadingSpinner from "../components/LoadingSpinner";

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;

const Members = () => {
  const [currentData, setCurrentData] = useState([]);
  const [formerData, setFormerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("current");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentResponse = await fetch(
          "https://api.airtable.com/v0/appa0JVKXzBq5kEuA/Current%20Members",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const currentData = await currentResponse.json();
        setCurrentData(currentData.records || []);

        const formerResponse = await fetch(
          "https://api.airtable.com/v0/appa0JVKXzBq5kEuA/Former%20Members",
          {
            headers: {
              'Authorization': `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`,
            },
          }
        );
        const formerData = await formerResponse.json();
        setFormerData(formerData.records || []);

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };
  
    fetchData(); 
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderMembers = () => {
    const renderMembersByCategory = (category, data) => {
      const membersWithCategory = data
        .filter(member => member.fields.Category === category)
        .sort((a, b) => a.fields["Sorting Order"] - b.fields["Sorting Order"]);

      return (
        <div key={category}>
          <h2 className="category-heading">{category}</h2>
          <div className="team-page-people">
            {membersWithCategory.map((record) => (
              <TeamCard
                key={record.id}
                name={record.fields.Name}
                picture={record.fields.Headshot && record.fields.Headshot[0] && record.fields.Headshot[0].url}
                position={record.fields.Role}
                email={record.fields.Email}
              />
            ))}
          </div>
        </div>
      );
    };

    return (
      <div className="section">
        {renderMembersByCategory("VSO Leaders", currentData)}
        {renderMembersByCategory("Current Team", currentData)}
        {renderMembersByCategory("Faculty & Mentors", currentData)}
      </div>
    );
  };

  const renderFormerMembers = () => {
    const sortedFormerData = formerData.sort((a, b) => a.fields.Year.localeCompare(b.fields.Year));
    
    const renderCategoryByYear = (category, isGrid) => {
      const membersByYear = sortedFormerData
        .filter(member => member.fields.Category === category)
        .reduce((acc, record) => {
          const year = record.fields.Year;
          const names = record.fields.Name.split(",");
          if (!acc[year]) acc[year] = [];
          acc[year].push(...names);
          return acc;
        }, {});

      return (
        <div className={isGrid ? "category-grid" : ""}>
          {Object.entries(membersByYear).map(([year, names]) => (
            <div key={year} className="year-group">
              <h3>{year}</h3>
              <ul>
                {names.map((name, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      );
    };

    return (
      <div className="section former-members-section">
        <div>
          <h2 className="category-heading">Student Alumni</h2>
          {renderCategoryByYear("Student Alumni", true)}
        </div>
        <div>
          <h2 className="category-heading">Former Mentors</h2>
          {renderCategoryByYear("Former Mentors", true)}
        </div>
        <div>
          <h2 className="category-heading">Former Faculty & PIs</h2>
          <div className="year-group">
            <ul>
              {sortedFormerData
                .filter(member => member.fields.Category === "Former Faculty & PIs")
                .map(record => (
                  <li key={record.id}>
                    <b>{record.fields.Year}:</b> {record.fields.Name}
                  </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Template>
      <div className="container">
        <div className="content-container">
          <div className="home-photo" style={{backgroundImage: `url(${GroupPhoto})`}}>
            <div className="overlay"></div>
            <div className="text-container-center">
              <h1>MEET THE TEAM</h1>
              <p>Featuring current and former research team members, VSO leaders, affiliated faculty, mentors, alumni, and more! </p>
            </div>
          </div>
          <div className="dropdown-container">
            <label htmlFor="member-type">Sort by:</label>
            <select id="member-type" value={selectedOption} onChange={handleOptionChange}>
              <option value="current">Current Members</option>
              <option value="former">Former Members</option>
            </select>
          </div>
          {selectedOption === "current" ? renderMembers() : renderFormerMembers()}
        </div>
      </div>
    </Template>
  );
};

export default Members;
