import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import IGEMLogo from '../images/StanfordiGEM.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav>
      <div className="nav-bar">
        <Link to="/">
          <img id="name-logo" src={IGEMLogo} alt="Name Logo" />
        </Link>
        <div className={`mobile-menu-icon ${isMobileMenuOpen ? 'white-icon' : 'black-icon'}`} onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
        </div>
        <ul className={`navbar-menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
          <li>
            <Link to="/projects" className={location.pathname === '/projects' ? 'active' : ''}>
              Projects
            </Link>
          </li>
          <li>
            <Link to="/members" className={location.pathname === '/members' ? 'active' : ''}>
              Members
            </Link>
          </li>
          <li>
            <Link to="/education" className={location.pathname === '/education' ? 'active' : ''}>
              Education
            </Link>
          </li>
          <li>
            <Link to="/news-and-photos" className={location.pathname === '/news-and-photos' ? 'active' : ''}>
              News & Photos
            </Link>
          </li>
          <li>
            <Link to="/join-us" className={location.pathname === '/join-us' ? 'active' : ''}>
              Join iGEM
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;