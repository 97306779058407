import React from 'react';
import './projectcard.css'; // Import the CSS file for styling

const ProjectCard = ({ imageUrl, title, summary, year, wikiLink }) => {
  const handleReadMoreClick = (event) => {
    event.preventDefault();
    window.open(wikiLink, '_blank');
  };

  return (
    <article className="article">
      <div className="article-wrapper">
        {imageUrl && (
          <figure>
            <img src={imageUrl[0].url} alt="Project" />
          </figure>
        )}
        <div className="article-body">
          <h3>{title}</h3>
          <p><strong>Year:</strong> {year}</p>
          <p><strong>Project Summary:</strong> {summary}</p>
          <a href={wikiLink} onClick={handleReadMoreClick} className="read-more" target="_blank" rel="noopener noreferrer">
            Read more <span className="sr-only">about {title}</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </a>
        </div>
      </div>
    </article>
  );
};

export default ProjectCard;
