const historyData = [
  {
    title: "2003",
    cardTitle: "iGEM Founded",
    cardSubtitle: "The Beginning",
    cardDetailedText: "iGEM (International Genetically Engineered Machine) is founded by Randy Rettberg, Drew Endy, and Tom Knight at MIT.",
  },
  {
    title: "2009",
    cardTitle: "Stanford Joins iGEM",
    cardSubtitle: "A Landmark Year",
    cardDetailedText: "Stanford University participates in the iGEM competition for the first time, hosted at MIT. The team presents a project focusing on a cell that could potentially aid in the self-repair of the human body, winning gold and the Best Health/Medicine Project award.",
  },
  {
    title: "2011",
    cardTitle: "Transite of Sythetic Astrobiology",
    cardSubtitle: "Research @ The NASA Ames Center",
    cardDetailedText: "Long-lasting collaboration between Stanford University and Brown University commences at the NASA Ames Research Center, with research focused on BioBricks that allow a cell to survive harsh extraterrestrial conditions. Stanford and Brown collaborates until 2019.",
  },
  {
    title: "2019",
    cardTitle: "Stanford-Brown-Princeton Collab",
    cardSubtitle: "Triple Collaboration",
    cardDetailedText: "Stanford University, Brown University, and Princeton University collaborate on an iGEM project, winning gold and the Best iGEMers Prize.",
  },
  {
    title: "2020",
    cardTitle: "First Educational Program",
    cardSubtitle: "Outreach Expands",
    cardDetailedText: "The 2020 iGEM Team starts Stanford iGEM's first free, educational program targeted towards high school students. The program earns a nomination for best education. This program is now named the Stanford iGEM Bioengineering Research Program (SIBRP) after its 2023 rendition.",
  },
  {
    title: "2022",
    cardTitle: "iGEM Hosted in Paris, France",
    cardSubtitle: "Stanford Travels to Paris",
    cardDetailedText: "The iGEM competition is hosted overseas in Paris, France, for the first time. Stanford iGEM travels to compete alongside 413 other registered teams.",
  },
  {
    title: "March 2024",
    cardTitle: "iGEM Becomes an Official VSO",
    cardSubtitle: "Voluntary Student Organization",
    cardDetailedText: "iGEM becomes an official Voluntary Student Organization (VSO) at Stanford University.",
  },
  {
    title: "April 2024",
    cardTitle: "BABS— A Bay Area Symposium",
    cardSubtitle: "Collaborative Effort",
    cardDetailedText: "Stanford iGEM collaborates with UC Berkeley, UC Santa Cruz, and UC Davis to host the inaugural Bay Area Bioengineering Symposium at UC Berkeley's campus.",
  },
];

export default historyData;
