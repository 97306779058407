import React from 'react';
import Template from '../components/Template';
import GroupPhoto from "../images/group_photo.JPG";
import Logo from "../images/S-logo.png";
import MedalGold from "../images/medal-gold-flat.svg";
import MedalSilver from "../images/medal-silver-flat.svg";
import MedalBronze from "../images/medal-bronze-flat.svg";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import "./Home.css";

import historyData from '../data/History.js';
import CountUp from '../components/CountUp.js';  // Import the CountUp component

// Importing Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faAtom, 
  faMicroscope,
  faVial,
  faDna,
  faCapsules,
  faFlaskVial,
  faSeedling
} from '@fortawesome/free-solid-svg-icons';

const Home = () => {
  const scrollToSection = (sectionId, offset = 0) => {
    const section = document.getElementById(sectionId);
    const offsetPosition = section.offsetTop - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  return (
    <Template>
      <div className="container">
        <div className="home-photo" style={{backgroundImage: `url(${GroupPhoto})`}}>
          <div className="overlay"></div>
          <div className="text-container">
            <img src={Logo} alt="S-logo" className="s-logo" />
            <h1>STANFORD IGEM</h1>
            <p>Stanford's Premier Synthetic Biology Student Organization Competing at the International Genetically Modified Machine Competition</p>
            <div className="buttons">
              <button onClick={() => scrollToSection('what-is-igem', 170)}>About iGEM</button>
              <button onClick={() => scrollToSection('get-involved', 170)}>Get Involved</button>
            </div>
          </div>
        </div>
        <div id="what-is-igem" className="section">
          <h2>WHAT IS IGEM?</h2>
          <p>Founded in 2003, the Internationally Genetically Engineered Machine (iGEM) is the largest annual synthetic biology competition in the world, comparable to the “genetic engineering Olympics.” Over two decades, iGEM has built a network of 50,000 alumni, with 6,000 competitors yearly. It's a driving force in applying genetic cellular engineering to healthcare and other fields, fostering innovation with tangible impacts, including 150 companies and 112 scientific publications. </p>
          <p>Stanford iGEM is a student-run, faculty-directed research organization at Stanford University. The objective of our interdisciplinary student group is to design and build novel engineered biological systems using standardized DNA-based parts to submit to the iGEM competition, held in Paris, France as of 2022. Stanford iGEM has excelled since 2009, earning 10 gold, 4 silver, and 1 bronze medals. Notable achievements include winning the Best New Application award in 2011, participating in World Championships like Stanford x Brown in 2012 and 2013, and collaborative successes like the iGEMers prize in 2019 with Brown and Princeton. Nominated for the best education and environmental project in 2023, Stanford iGEM continues to lead in synthetic biology.</p>
          <div className="medal-section">
            <div className="medal-column">
              <img src={MedalGold} alt="Gold Medal" />
              <div className="medal-count gold">
                <CountUp end={10} duration={2000} />  {/* Use the CountUp component */}
              </div>
            </div>
            <div className="medal-column">
              <img src={MedalSilver} alt="Silver Medal" />
              <div className="medal-count silver">
                <CountUp end={4} duration={2000} />   {/* Use the CountUp component */}
              </div>
            </div>
            <div className="medal-column">
              <img src={MedalBronze} alt="Bronze Medal" />
              <div className="medal-count bronze">
                <CountUp end={1} duration={2000} />   {/* Use the CountUp component */}
              </div>
            </div>
          </div>
          <div id="igem-history">
            <h3>Stanford iGEM's History</h3>
            <VerticalTimeline lineColor="#53565A">
              {historyData.map((event, index) => (
                  <VerticalTimelineElement
                    key={index}
                    className="vertical-timeline-element"
                    contentStyle={{ background: '#fafafa', color: '#000' }}
                    contentArrowStyle={{ borderRight: '7px solid #53565A' }}
                    date={event.title}
                    iconStyle={{ background: '#8C1515', color: '#fff' }}
                    icon={<FontAwesomeIcon icon={index % 7 === 0 ? faSeedling : 
                                             index % 7 === 1 ? faMicroscope :
                                             index % 7 === 2 ? faVial :
                                             index % 7 === 3 ? faDna :
                                             index % 7 === 4 ? faCapsules :
                                             index % 7 === 5 ? faFlaskVial :
                                             faAtom} />} 
                  >
                    <h3 className="vertical-timeline-element-title">{event.cardTitle}</h3>
                    <h4 className="vertical-timeline-element-subtitle">{event.cardSubtitle}</h4>
                    <p>{event.cardDetailedText}</p>
                  </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>
        </div>
        <div id="get-involved" className="section">
          <h2>GET INVOLVED</h2>
          <div className="get-involved-section">
            <div className="partner-with-us">
              <h3>Partner with Us</h3>
              <p>Hello! Thank you for expressing interest in Stanford iGEM! If you are a corporate representative or non-profit organization interested in collaboration opportunities or sponsoring Stanford iGEM, please email contact our secondary principal investigator (PI), Dr. Huijun Ring at <b><a href="mailto:ring@stanford.edu">ring@stanford.edu</a></b> or current VSO President, Julia Vu at <b><a href="mailto:julia@stanford.edu">juliavu@stanford.edu</a></b>.</p>
            </div>
            <div className="stanford-student">
              <h3>Stanford Student? Join Us Today!</h3>
              <p>Are you a current Stanford undergraduate or graduate student? Interested in bridging cutting-edge research with hands-on synthetic biology projects? Join our team at Stanford iGEM and be part of the pioneering community shaping the future of biotechnology. Connect with fellow students, learn from experienced mentors, and contribute to innovative projects that tackle real-world challenges. Applications to join the annual research team roll out in Winter Quarter, but you are always welcomed to join the community at any time! Read the "Join iGEM" page for more details. </p>
              <div className="buttons">
                <button onClick={() => window.location.href = "/join-us"}>Join the iGEM Community</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Home;
