import React, { useState, useEffect } from 'react';
import Template from '../components/Template';
import LoadingSpinner from '../components/LoadingSpinner';
import "./Education.css";

import GroupPhoto from "../images/SWExiGEM.jpg";
import BioArt from "../images/getSET_bioart.jpg";
import Game from "../images/phils_laberia.gif";

const { REACT_APP_AIRTABLE_API_TOKEN } = process.env;
const AIRTABLE_API_URL = 'https://api.airtable.com/v0/appa0JVKXzBq5kEuA/Links?maxRecords=3&view=Grid%20view';

const Education = () => {
  const [applicationLink, setApplicationLink] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(AIRTABLE_API_URL, {
          headers: {
            Authorization: `Bearer ${REACT_APP_AIRTABLE_API_TOKEN}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        if (data.records && data.records.length > 0) {
          const sibrpRecord = data.records.find(record => record.fields.Name === 'SIBRP Application Link');
          if (sibrpRecord) {
            const link = sibrpRecord.fields['Link'];
            setApplicationLink(link);
          }
        }
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data from Airtable:', error);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    fetchData();
  }, []);

  const scrollToSection = (sectionId, offset = 0) => {
    const section = document.getElementById(sectionId);
    const offsetPosition = section.offsetTop - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };

  const renderApplicationButton = () => {
    if (!applicationLink) {
      return (
        <p className="red-link"><b>Applications are currently closed, but reopen around June every year.</b></p>
      );
    } else {
      return (
        <div className="buttons">
          <button onClick={() => window.open(applicationLink, '_blank')}>Apply Now</button>
        </div>
      );
    }
  };

  if (loading) {
    return <LoadingSpinner />; // Display loading spinner while fetching data
  }

  return (
    <Template>
      <div className="container">
        <div className="content-container">
          <div className="home-photo" style={{backgroundImage: `url(${GroupPhoto})`}}>
            <div className="overlay"></div>
            <div className="text-container-center">
              <h1>EDUCATION</h1>
              <p>Join Stanford iGEM for educational initiatives such as the Stanford iGEM Bioengineering Research Program, collaborations with the Society of Women Engineers, and more!</p>
              <div className="buttons">
                <button onClick={() => scrollToSection('sibrp', 170)}>SIBRP Program</button>
                <button onClick={() => scrollToSection('game', 170)}>Play Our Game</button>
                <button onClick={() => scrollToSection('swe-getset', 170)}>SWE GetSET</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="sibrp" className="section">
        <h2>STANFORD IGEM BIOENGINEERING RESEARCH PROGRAM (SIBRP)</h2>
        <p>The Stanford iGEM Bioengineering Research Program (SIBRP) is a dynamic and inclusive initiative designed to introduce high school students and incoming college freshmen to the exciting world of synthetic biology and bioengineering. Over the course of four weeks, participants engage in an online multifaceted learning experience, consisting of a synthetic biology speaker series, lab webinar, and mentorship program. Organized and executed by the Stanford iGEM Team, SIBRP fosters intellectual curiosity, allows students to develop their own research proposals, and inspires the next generation of bioengineers.</p>
        <p>All high school and incoming first-year college students are encouraged to apply. There is no application fee and the program is completely free. Feel free to contact us for more information!</p>
        {renderApplicationButton()}
      </div>

      <div id="game" className="section">
        <h2>BIOENGINEERING GAME</h2>
        <p className="red-link">Developed by our team and named after one of our mentors, “Phil’s Laberia,” is an innovative bioengineering laboratory simulation game. Designed to democratize access to advanced wet lab techniques for aspiring bioengineers, this engaging and educational game is poised to revolutionize science education. With a user-friendly interface and immersive virtual lab experiences, “Phil’s Laberia” offers a unique opportunity to foster a deeper understanding of the field, equipping players with the skills and knowledge needed to excel in the field of bioengineering while breaking down barriers to entry. <b><a href="https://knockdev.itch.io/phils-laberia" target="_blank" rel="noopener noreferrer">Play our game here: https://knockdev.itch.io/phils-laberia</a></b></p>
        <div className="image-container">
          <img src={Game} alt="Game Demo of Phil's Laberia" />
          <p>Game Demo of Phil's Laberia</p>
        </div>
      </div>

      <div id="swe-getset" className="section">
        <h2>SWE GETSET x IGEM</h2>
        <p>The Society of Women Engineers (SWE) is one of the largest organizations supporting women in engineering and technology, and a leading advocate and catalyst for transformative change worldwide. Under the Santa Clara Valley Section of SWE, Stanford iGEM hosted a Synthetic Biology Workshop with the Get Science, Engineering, and Technology (GetSET) Program. GetSET is an award-winning outreach program that has been running for over 30 years. Participants interested in STEM connected with over 100 peers, industry professionals, and volunteers experienced in engineering fields. Since 2023, our team has worked with SWE to run a synthetic biology workshop, where we introduce research and career opportunities with participants, as well as fun activities such as Bio-Art.</p>
        <div className="image-container">
          <img src={BioArt} alt="GetSET Participants in 2023 holding up Bio-Art Plates" />
          <p>GetSET Participants in 2023</p>
        </div>
      </div>
    </Template>
  );
};

export default Education;
